.container {
  border-radius: 10px;
  border: 0.8px solid rgba(0, 0, 0, 0.23);
  width: 100%;
  padding: 21px 16px 8px;
  position: relative;
}

.info {
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  line-height: 143%;
  letter-spacing: 0.15px;
  margin: 30px 0 30px !important;
}

.table {
  border-collapse: collapse;
  width: 100%;
  text-wrap: nowrap;
}

.table th,
.table td {
  text-align: left;
  padding: 16px;
  box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.12) inset;
  font-size: 14px;
}

.table tfoot th,
.table tfoot td {
  font-weight: bold;
  box-shadow: none;
}

.tableWrapper {
  width: 100%;
  overflow-x: auto;
}

.tableWrapper::-webkit-scrollbar {
  width: 8px;
}

.tableWrapper::-webkit-scrollbar-thumb {
  background-color: #299b9b;
  border-radius: 20px;
  border: 4px solid #fff;
}

.tableWrapper::-webkit-scrollbar-track {
  background-color: #fff;
  width: 8px;
}

.cardTableMobile {
  box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.12) inset;
}
