.container {
  border-radius: 10px;
  border: 0.8px solid rgba(0, 0, 0, 0.23);
  background: #fff;
  width: 100%;
  padding: 16px 0;
  position: relative;
  min-height: 200px;
  max-height: 510px;
  height: 100%;
}

.cardTitle {
  font-size: 20px !important;
}

@media (max-width: 768px) {
  .cardTitle {
    font-size: 16px !important;
  }
}
