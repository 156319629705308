.additional-remarks {
  display: flex;
  flex-direction: column !important;
  justify-content: flex-end !important;
}

.favorite-icon {
  background-color: #dbefef;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 28px;
  height: 28px;
}

.header-xs {
  display: none;
}

.contato-xs {
  position: relative;
}

.favorite-icon-xs {
  display: none;
}

@media (max-width: 600px) {
  .table-header {
    display: none;
  }
  .header-xs {
    display: block;
  }

  .contact-description {
    margin: 16px 0 !important;
  }

  .favorite-icon-sm {
    display: none;
  }

  .favorite-icon-xs {
    display: flex;
  }

  .contato-xs {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
