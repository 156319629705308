.card-item {
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 10px;
  position: relative;
  width: 100%;
  min-height: 149px;
  &:hover {
    cursor: pointer;
  }

  &__content {
    padding: 32px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 40px;

    p {
      font-size: 14px;
      font-weight: bold;
      margin: 0;
      margin-bottom: 8px;
    }

    span {
      margin: 0;
      font-size: 12px;
    }

    &__date {
      color: rgba(0, 0, 0, 0.87) !important;
      font-size: 10px !important;
      font-weight: normal !important;
    }
  }

  .side {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    position: absolute;
    width: 8px;
    height: 100%;

    &-yellow {
      background-color: #ffb547;
    }

    &-green {
      background-color: #238884;
    }

    &-red {
      background-color: #f44336;
    }

    &-gray {
      background-color: #bdbdbd;
    }
  }
}

.chip {
  height: 24px;
  width: fit-content;
  padding: 3px 6px 3px 6px;
  border-radius: 16px;

  color: white;
  font-size: 13px !important;
  font-weight: 400 !important;
  &-blue {
    background-color: rgba(33, 150, 243, 0.5);
  }

  &-red {
    background-color: #f88078;
  }

  &-gray {
    background-color: #bdbdbd;
  }
}

.disabled {
  &:hover {
    cursor: auto !important;
  }
}
