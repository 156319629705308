.itemFunnelBar {
  height: 62px;
  text-align: center;
  padding-top: 17.44px;
  color: white;
  font-size: 16px;
  font-weight: 700;
  position: relative;
  margin-bottom: 23px;

  .shadowFunnelBar {
    content: '""';
    width: 100%;
    height: 24.5px;
    position: absolute;
    bottom: -24px;
    opacity: 0.5;
    rotate: 180deg;
  }

  &:hover {
    .shadowFunnelBar {
      opacity: 1;
    }
    opacity: 0.5;
  }
}

.tooltipFunnel {
  background-color: white;
  position: absolute;
  transition: 0.2s;
  color: black;

  border-radius: 8px;
}
