.filtersInfo {
  border-radius: 4px;
  border: 1px dashed rgba(0, 0, 0, 0.23);
  background: #eee;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 12px 15px;
  margin: 16px 0 32px;
}

.testsDoneCard {
  border-radius: 10px;
  border: 0.8px solid rgba(0, 0, 0, 0.23);
  background: #fff;
  padding: 21px 0 16px;
  position: relative;
  flex: 1;
  max-height: 550px;
  margin-bottom: 16px;
  position: relative;  /* height: 700px; */
}

.testsDoneCardTitle {
  margin-left: 17px;
  margin-bottom: 24px;
}

.chartsList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 16px;
  row-gap: 32px;
  margin-top: 27px;
}

.testsDoneCardTitle h5:first-child {
  font-size: 20px;
}

.testsDoneCardTitle h5:last-child {
  font-size: 76px;
}

@media (max-width: 768px) {
  .testsDoneCardTitle h5:first-child {
    font-size: 16px;
  }

  .testsDoneCardTitle h5:last-child {
    font-size: 48px;
  }
}
