.cardStatusResultsHpv {
  color: white;
  padding: 16px 16px 16px 24px;
  border-radius: 10px;
  height: 204px;
  position: relative;

  p {
    margin: 0;
  }

  &__title {
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
  }

  &__updatedAt {
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
  }

  &__total {
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    margin-top: 16px !important;
    margin-bottom: 8px !important;
  }

  &__originData {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: 0;
    right: 0;
    margin-bottom: 16px;
    margin-right: 16px;
    width: 105px;
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
  }
}

.brown {
  background-color: #c77700;
  border: 1px solid #995b00;
}

.green {
  background-color: #238884;
  border: 1px solid #1b6965;
}

.lightGreen {
  background-color: #4caf50;
  border: 1px solid #3a873d;
}
