.itemGraphic {
  display: flex;
  width: 100%;
  border-bottom: 0.8px solid rgba(0, 0, 0, 0.12);
  align-items: center;
  height: 65px;

  p {
    width: 152px;
    margin: 0;
    font-size: 14px;
    color: #757575;
    font-weight: 500;
  }

  &__boxGraphic {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    margin-left: 27px;
    height: 100%;

    &__chartColorized {
      &:hover {
        opacity: 0.5;
      }
      color: white;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 26px;
    }

    &__chartDisabled {
      &:hover {
        opacity: 1;
      }
      color: white;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 26px;
    }
  }
}

.remove-border {
  border: none !important;
}
