.MuiToolbar-root,
.MuiToolbar-gutters,
.MuiToolbar-regular,
.css-198nu5u-MuiToolbar-root {
  padding-left: 19px;
  padding-right: 19px;
}

.list-item {
  padding-top: 16px;
  padding-bottom: 16px;
}

.notification {
  margin-right: 1.5rem;
}
.notificationList {
  position: absolute;
  padding: 24px;
  z-index: 1;
  top: 3.4rem;
  right: 6.3em;
  bottom: 0;
  height: fit-content;
  max-height: 586px;
  max-width: 33.75rem;
  width: 18.438rem;
  height: 26.063rem;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}

.centralNotificationButton {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  margin-top: 16px;
  // margin-bottom: 24px;

  button {
    color: #0b79d0;
    border: none;
    background: transparent;
    font-weight: 700;
    text-decoration: underline;
    font-size: 12px;
    &:hover {
      color: #095d9e;
      cursor: pointer;
    }
  }
}
