.modalBody {
  width: 80%;
  height: fit-content;
  max-height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fdfdfd;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3%;
  padding: 2rem;
  border-radius: 10px;
  overflow-y: auto;
  font-size: 1rem;
  line-height: 24px;
  letter-spacing: 0.15px;
  font-weight: 400;
  max-width: 861px;
}

.titleBoxModal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.15px;
}

.icon {
  font-size: 33.33px;
  margin-right: 1rem;
}

.buttonsBoxModal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 1rem;
}

.buttonModal {
  font-size: 14px;
  font-weight: 500;
  width: 127px;
  height: 36px;
  margin: 0 0.5rem;
  display: inline-flex !important;
  white-space: nowrap;
}

.cancelModal {
  height: 36px !important;
}

.subModalTextBody {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 32px;

  border: solid 1px #bdbdbd;
  border-radius: 10px;

  margin-top: 24px;
}

.subModalBody {
  max-width: 1120px;
  height: 85%;
  max-height: 750px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fdfdfd;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4.3%;
  padding: 25px 0;
  border-radius: 10px;
  overflow-y: auto;
}

.subModalBodyChoiceExamItem p {
  margin: 0;
  font-weight: bold;
}

.clickEffect {
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.clickEffect:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 0.00000003s ease-out;
}

.clickEffect:active:after {
  transform: scaleX(1);
  transition: transform 0.15s;
}

.buttonModalContainer {
  width: 813px;
  height: 36px;
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.modalConfirmaResultadoBody {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
  /* width: 464px; */

  background-color: #ffffff;
  border-radius: 12px;
  padding: 48px 24px 24px 24px;

  h1 {
    margin: 0;
  }

  p {
    text-align: center;
  }
}
