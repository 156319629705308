.notificationBox {
  width: 100%;
  height: fit-content;
  background-color: #fdfdfd;
  color: #494949;
  /* border-radius: 10px; */
  /* margin: 0.5rem; */
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);  */
  cursor: pointer;
  display: flex;
  border-bottom: 1px solid #e0e0e0;
  gap: 8px;
  margin-top: 16px;
}

.titleNotificationBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  font-size: 14px;
  font-weight: 700;
  line-height: 21.98px;
  letter-spacing: 0.1px;
  text-align: start;
}

.notificationBoxText {
  font-size: 12px;
  font-weight: 400;
  line-height: 19.92px;
  letter-spacing: 0.4px;
}
