.boxData {
  padding: 0px;
  margin: 0px;

  &__legends {
    display: flex;
    align-items: center;
    margin-bottom: 26px;
    &__colorizedLegend {
      width: 12px;
      height: 12px;
      margin-right: 8px;
    }
  }
}
